/**
 * @Author          : ZhangLianWei
 * @Date            : 2024-07-01 18:03:42
 * @LastEditors     : SuXiao xiao.su_ext@novartis.com
 * @LastEditTime    : 2025-02-18 17:35:58
 * @FilePath        : \wecom-front-end-admin-portal\src\services\nuoxiaoer-service.ts
 * @Description     :
 * @
 */
import {Inject} from '@cds/common';
import {HttpService} from '@/services/common/http.service';

export class NuoxiaoerService {
  @Inject(HttpService) private http!: HttpService;
  //添加
  public articleAdd(param: Dict<any>): Promise<any> { //wecom-buddy-backend/api/article/admin/queryArticleList
    return this.http.post('/wecom-buddy-backend/api/article/admin/addArticle', param);
  }
  //查询
  public queryArticleList(param: Dict<any>): Promise<any> {
    return this.http.post('/wecom-buddy-backend/api/article/admin/queryArticleList', param);
  }
  //详情
  public queryArticleInfo(id: Dict<any>): Promise<any> {
    return this.http.get(`/wecom-buddy-backend/api/article/admin/detail/${id}`);
  }
  //删除
  public queryArticleDelete(param: Dict<any>): Promise<any> {
    return this.http.post('/wecom-buddy-backend/api/article/admin/deleteArticleById', param);
  }
  //删除
  public updateArticleById(param: Dict<any>): Promise<any> {
    return this.http.post('/wecom-buddy-backend/api/article/admin/updateArticleById', param);
  }
  //根据所属类型获取应用/业务场景名称
  public queryAppOrScenarioByType(param: Dict<any>): Promise<any> {
    return this.http.post('/wecom-buddy-backend/api/article/admin/queryAppOrScenarioByType', param);
  }
  //根据所属类型获取应用/业务场景名称
  public querySecondMenuByAppOrScenarioId(param: Dict<any>): Promise<any> {
    return this.http.post('/wecom-buddy-backend/api/article/admin/querySecondMenuByAppOrScenarioId', param);
  }

  public fetchCommonConfig(param: Dict<any>): Promise<any> {
    return this.http.get('/wecom-buddy-backend/api/config/configurations', param);
  }

  public fetchUserActions(param: Dict<any>): Promise<any> {
    return this.http.post('/wecom-buddy-backend/api/user/getHistoryInfo', param);
  }



}
